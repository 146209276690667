@import url("bootstrap/dist/css/bootstrap.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");

:root {
  --bs-modal-bg: #ffffff !important;
  --bs-body-bg: #e1f7ee;
  --bg-pale: #e1f7ee;
  --primary-light: #004080;
  --primary-main: #003366;
  --primary-dark: #00264d;
  --secondary-light: #66d2b6;
  --secondary-main: #52ccad;
  --secondary-dark: #3dc7a4;
  --light-neutral-light: #ffffff;
  --light-neutral-main: #fafffa;
  --light-neutral-dark: #e1f7ee;
  --bs-btn-active-bg: #00264d !important;
}
.source-sans-regular {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.source-sans-semibold {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.source-sans-bold {
  font-family: "Source Sans 3", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.montserrat-semibold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

body,
small,
button,
label {
  font-family: "Source Sans 3", sans-serif;
  font-size: clamp(1rem, 0.9512195121951219rem + 0.24390243902439024vw, 1.125rem);
  line-height: 1.33em;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
h1 {
  font-size: clamp(2rem, 1.3333333333333335rem + 3.3333333333333335vw, 4rem);
  line-height: clamp(1.101em, 0.9512195121951219rem + 0.24390243902439024vw, 2.101em);
}
h2 {
  font-size: clamp(1.5rem, 1.3333333333333333rem + 0.8333333333333334vw, 2rem);
  line-height: clamp(1.1em, 0.9512195121951219rem + 0.24390243902439024vw, 1.6em);
}
h3 {
  font-size: clamp(1.25rem, 1.1666666666666667rem + 0.4166666666666667vw, 1.5rem);
  line-height: clamp(1em, 0.9512195121951219rem + 0.24390243902439024vw, 1.3em);
}
h4 {
  font-size: 1.125em;
  line-height: clamp(1.2em, 0.9512195121951219rem + 0.24390243902439024vw, 1.9em);
}
h5 {
  font-size: 1em;
  line-height: clamp(1.1em, 0.9512195121951219rem + 0.24390243902439024vw, 1.7em);
}
h6 {
  font-size: 0.8em;
  line-height: clamp(1.1em, 0.9512195121951219rem + 0.24390243902439024vw, 1.3em);
}
.modal-content {
  background-color: #ffffff !important;
}

.form-control {
  border-color: transparent;
}
.form-control:focus,
.form-control:active,
.form-control:focus-within,
.form-select:focus {
  border-color: var(--secondary-light);
  background-color: var(--light-neutral-light);
  box-shadow: 0 0 0 0.25rem rgba(62, 198, 164, 0.25);
}
child:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  color: var(--light-neutral-main);
}

.form-check-input:checked,
.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--secondary-light);
  border-color: var(--secondary-dark);
}

/* Bootstrap overrides and global style */

/* Custom scrollbars */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: var(--secondary-light);
}

.table {
  /* override default table background */
  --bs-table-striped-bg: transparent;
}
/* override default table colors */
.table > :not(caption) > * > * {
  color: var(--primary-dark);
}
.table-striped > tbody > tr:nth-of-type(odd) > *,
.table-striped > tbody > tr:nth-of-type(even) > * {
  color: var(--primary-dark);
}
.table > :not(caption) > * > th {
  background-color: #e2f1e2;
}
/* override default td borders */
.table > :not(caption) > * > td {
  background-color: transparent;
}

/* Links that contain icons */
a > i {
  color: var(--primary-main);
}

/* Secondary buttons */
.btn {
  font-family: "Source Sans 3", sans-serif;
  border-radius: 5px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: -1px 1px rgba(0, 0, 0, 0.2);
}
.btn-text.show {
  background-color: transparent !important;
}
.btn-primary {
  background-color: var(--primary-main);
  color: white;
  font-weight: 500;
  border: none;
}
.btn-primary:hover,
.btn-primary.btn:active {
  background-color: var(--primary-dark);
}
.btn-secondary {
  background-color: var(--secondary-light);
  color: black;
  font-weight: 500;
  border: none;
}
.btn-secondary:hover,
.btn-secondary.btn:active {
  background-color: var(--secondary-dark);
  color: black;
}
.btn-light {
  background-color: var(--neutral-light);
  color: var(--primary-dark);
  border: none;
}
.btn-dark {
  background-color: var(--primary-main);
}

.btn-dark:hover,
.btn-dark.btn:active {
  background-color: var(--primary-dark);
}
.dropdown-toggle {
  box-shadow: none;
}

.btn-outline-primary {
  color: var(--primary-dark);
  background-color: transparent;
  border-color: var(--primary-main);
  border-width: 2px;
}
.btn-outline-primary:hover,
.btn-outline-primary.btn:active {
  color: var(--light-neutral-main);
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  border-width: 2px;
}
.btn-outline-dark {
  color: var(--primary-dark);
  border-color: var(--primary-dark);
  border-width: 2px;
}
.btn-outline-dark:hover,
.btn-outline-dark.btn:active {
  color: var(--light-neutral-main);
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
  border-width: 2px;
}
.btn-danger {
  box-shadow: none;
}
.btn-success {
  border: none;
}

.form-control,
.form-select {
  background-color: var(--bg-pale);
  color: var(--primary-dark);
  padding: 8px 16px;
}
.form-check-input {
  /* background-color: var(--bg-pale); */
  background-color: white;
  box-shadow: -1px 1px rgba(0, 0, 0, 0.1);
  /* border: 1px solid var(--primary-dark); */
}
.form-control:active,
.form-control:focus {
  background-color: white;
}

.form-select:active {
  background-color: white !important;
}

.dropdown-toggle.btn.show,
.dropdown-toggle.btn:active {
  border-color: var(--secondary-light);
  background-color: var(--light-neutral-light);
  box-shadow: 0 0 0 0.25rem rgba(62, 198, 164, 0.25);
  color: var(--primary-dark);
}

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  color: var(--primary-dark);
}

.text-right {
  text-align: right;
}

.form-group {
  margin-bottom: 12px;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.ml-4 {
  margin-left: 2rem;
}

.ml-5 {
  margin-left: 3rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-1 {
  margin-left: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mr-4 {
  margin-right: 2rem;
}

.mr-5 {
  margin-right: 3rem;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.max-content {
  width: max-content;
}
.bg-resolved {
  background-color: #33ff57;
}
.bg-inprogress {
  background-color: #003366;
}
.bg-onhold {
  background-color: #ffcb33;
}
.bg-assigned {
  background-color: #ff8c33;
}
.bg-unassigned {
  background-color: #b2b2b2;
}
.bg-closed {
  background-color: #ff3333;
}
.border-resolved {
  border-color: #33ff57 !important;
}
.border-inprogress {
  border-color: #337aff !important;
}
.border-onhold {
  border-color: #ffcb33 !important;
}
.border-assigned {
  border-color: #ff8c33 !important;
}
.border-unassigned {
  border-color: #b2b2b2 !important;
}
.border-closed {
  border-color: #ff3333 !important;
}
.btn-danger {
  background-color: #f7e3e5;
  border: none;
  color: #ff3333;
}
.btn-danger:hover {
  color: #f7e3e5;
}

.form-range::-moz-range-track {
  background-color: #b2b2b2;
}

.shadow-light {
  box-shadow: 2px 2px 8px 0px rgba(67, 114, 133, 0.13);
}
