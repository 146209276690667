.separation-mark {
  opacity: 0;
  text-align: center;
  margin-left: -8px;
  transition: opacity 0.25s ease-in-out; /* add transition property */
}
.separation-mark-container {
  width: 18px;
  cursor: pointer;
}
.separation-mark-container:hover .separation-mark {
  opacity: 1; /* change opacity to 1 on hover */
}

.separation-mark-separated {
  opacity: 1;
  text-align: center;
  margin-left: -8px;
}

.page-item-container #delete-page-button {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.page-item-container:hover #delete-page-button {
  opacity: 1;
}
