.search-form {
  position: relative;
}

.search-form .fa-search {
  position: absolute;
  top: 12px;
  right: 12px;
  color: var(--primary-dark);
}
.search-form-input:focus ~ .fa-search {
  color: var(--secondary-dark);
}

.search-form-input {
  padding-right: 40px !important;
}
