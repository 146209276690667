.page-header {
  z-index: 100;
  display: flex;
  position: sticky;
  top: 0px;
  box-shadow: 2px 2px 8px 0px rgba(67, 114, 133, 0.1);
  background-color: var(--light-neutral-main);
  display: flex;
  padding: 16px;
  flex-direction: column;
}
