.td-pressable:hover {
  text-decoration: underline;
}

.table-actions {
  position: absolute;
  right: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  font-size: clamp(1rem, 0.9512195121951219rem + 0.24390243902439024vw, 1.125rem);
}
tbody tr:hover .table-actions {
  background-color: var(--light-neutral-main) !important;
  opacity: 1;
  transition: opacity 0.3s;
}
.table-actions-visible {
  background-color: var(--light-neutral-main) !important;
  opacity: 1;
  transition: opacity 0.3s;
}
@media (hover: none) {
  .table-actions {
    background-color: var(--light-neutral-main) !important;
    opacity: 1;
  }
}

tbody tr:hover {
  box-shadow: 2px 2px 8px 0px rgba(67, 114, 133, 0.25);
}

td,
th {
  white-space: nowrap !important;
  min-width: fit-content !important;
  vertical-align: middle !important;
}
table {
  margin-bottom: 0 !important;
}
table th,
.table th {
  border: none;
  border-right: solid;
  border-style: solid;
  border-width: 4px;
  background-color: var(--light-neutral-dark) !important;
  border-color: var(--light-neutral-dark);
}

th input[type="checkbox"],
td input[type="checkbox"] {
  margin-top: 3px;
}

td input[type="checkbox"] {
  margin-left: 2px;
}
.td-no-data {
  background-color: white !important;
}
.table-container {
  position: relative;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
}

.table-body {
  max-height: 300px; /* Set your desired height */
  overflow-y: auto;
}

.pagination-container {
  /* Your pagination styles */
}

.table-action-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.table-action-icon:hover,
.table-action-icon.active {
  color: var(--light-neutral-light);
  background-color: var(--secondary-dark);
}
