/* drawer overlay */

.drawer-overlay {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  z-index: 2000;
  transition: transform 0.3s ease-out;
}

.overlay-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  top: 0;
}

/* drawer side */

.drawer-side-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 65px);
  background-color: #ebf9f6;
}

.drawer-side {
  height: calc(100vh - 65px);
  position: fixed;
  transition: width 0.3s ease-out;
  top: 65px;
}

.drawer-side-main {
  height: 100%;
  width: 100%;
  transition: padding-left 0.3s ease-out, padding-right 0.3s ease-out;
  position: relative;
}
