.stat-card {
  border-radius: 16px;
  background-color: var(--light-neutral-main);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);
  display: flex;
  flex: 1 0 0;
}
.stat-card-label {
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
}
