.back-button {
  width: 32px;
  height: 32px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  cursor: pointer;
}
