.dropdown-toggle::after {
  display: none !important;
}

.apply > .dropdown-item {
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  background-color: var(--primary-main);
  color: white;
}

.apply > .dropdown-item:hover {
  background-color: var(--primary-dark);
  color: white;
}

.dropdown-indicator::after {
  display: block !important;
}
.dropdown-menu.show {
  background-color: var(--light-neutral-light);
}
