/* declaring position relative, just to have child elements positioned compared to it */
#datepicker {
  position: relative;
  min-width: 10rem;
}
#datepicker-input {
  width: 100%;
}
.datepicker-toggle-button {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 4px;
  top: calc(50% - 10px);
}
.datepicker-toggle-button:hover {
  cursor: pointer;
}
.datepicker-hidden-input {
  position: absolute;
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.datepicker-hidden-input::-webkit-calendar-picker-indicator {
  position: absolute;
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
