.card-default {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  background-color: #ffffff;
  min-width: "max-content";
  gap: 4px;
  width: 100%;
}

.card-default:hover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.33);
}

.card-container {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 16px;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 3px;
}
