.signature-card {
  background-color: var(--light-neutral-dark);
  border-radius: 8px;
  position: relative;
  gap: 8px;
  display: flex;
  flex-direction: column;
}
.signature-card .dismiss-signature {
  opacity: 0;
  display: none;
  position: absolute;
}
.signature-card:hover .dismiss-signature {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.25s ease-in-out;
}
.dismiss-signature {
  right: 8px;
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 8px 0px rgba(67, 114, 133, 0.25);
}
.dismiss-signature:hover i {
  color: #dc3545 !important;
}
