@keyframes opacityChange {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.opacity-change {
  animation: opacityChange 1000ms ease-in-out infinite;
}
.draft-document-card {
  background-color: #e9ebec;
  border-radius: 8px;
}
.draft-document-card .dismiss-draft-document-icon {
  opacity: 0;
}
.draft-document-card:hover .dismiss-draft-document-icon {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}
.add-document-card {
  background-color: #e9ebec;
  border-radius: 8px;
  box-shadow: 2px 2px 4px 0px rgba(108, 117, 125, 0.25) inset;
  cursor: pointer;
}

.add-document-card:hover {
  background-color: #e9ebeca4;
  transition: background-color box-shadow 0.25s ease-in-out;
  box-shadow: 2px 2px 4px 0px rgba(108, 117, 125, 0.125) inset;
}
.add-document-card span {
  color: #6c757dd7;
}
.add-document-card i {
  color: rgb(118, 120, 122) !important;
}
.add-document-card:hover span,
.add-document-card:hover i {
  color: rgba(0, 0, 0) !important;
  transition: color 0.25s ease-in-out;
}
